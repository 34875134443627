.myjumbo {
  background-image: url("background.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  color: #efefef;
  height: 540px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Raleway";
}

.maintext {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: space-around;
  flex-direction: column;
  padding-top: 25vh;
  vertical-align: middle;
}
.btnbox {
  padding-top: 30vh;

  position: relative;
  overflow: hidden;
  display: block;
}
.btnmain {
  text-decoration: none;
  border: 3px solid white;
  background: none;
  padding: 4px;
  color: white;
  font-family: "Raleway";
  font-size: 21px;
  cursor: pointer;
  transition: 0.8s;
  position: relative;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
}
.btnshop {
  text-decoration: none;
  border: 3px solid white;
  background: none;
  z-index: 11;
  padding: 4px;

  color: white;
  font-family: "Raleway";
  font-size: 21px;
  cursor: pointer;
  transition: 0.8s;

  position: relative;
}
.btnshop:hover {
  color: grey;

  border: 3px solid white;
  z-index: 100;
}
.btnshop::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: white;
  z-index: 10;
  transition: 0.8s;
  top: 0;
  border-radius: 0 0 50% 50%;
  color: grey;
}
.btnshop:hover::after {
  height: 180%;
  color: grey;
  z-index: -10;
}
