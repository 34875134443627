.hi {
  background-image: url(./background.jpg);
  background-size: cover;
}

.jumboShop {
  background-image: url(./shopsnow.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  color: black;
  height: 480px;
  font-family: "Raleway";
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shopwords {
  color: black;

  font-family: "Raleway";

  display: flex;
  justify-content: center;
  align-items: center;
}

.jumboAbout {
  background-image: url(./bridge.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  color: #efefef;
  height: 480px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Raleway";
}

.jumboWords {
  color: black;

  font-family: "Raleway";

  display: flex;
  justify-content: center;
  align-items: center;
}
.words {
  display: flex;
  justify-content: space-around;
  align-items: center;

  vertical-align: middle;
  padding-top: 0px;
}
.footerinsta {
  color: white;
  margin: 0 1rem;
  display: inline-block;
  transition: transform 250ms;
  font-size: 24px;
  text-align: center;
  align-items: center;
}
.footerinsta:hover {
  color: grey;
}
.bodyCard {
  padding: 30px;
  margin: 0px;
  width: 600px;

  border: 3px solid #eee;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
  justify-content: space-around;
  flex-wrap: wrap;
}
.bodyCard img {
  width: 100%;
  padding: 0px;
}
.bodyCard p {
  margin: 20px;
  padding: 0px;
  font: "Raleway";
  font-size: 17px;
  letter-spacing: 1px;
}
.bodyCard h2 {
  margin: 20px;
  padding: 0px;
  font: "Raleway";
  font-size: 35px;
  letter-spacing: 1px;
}
.bodyCard h3 {
  margin: 20px;
  padding: 0px;
  font: "Raleway";
  font-size: 25px;
  letter-spacing: 1px;
}
.buy {
  height: 40px;
  width: 90%;
  margin: 0px 5% 20px;
  border: none;
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.3);
}
.cards {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto auto auto;
  padding: 30px;
  box-sizing: border-box;
  grid-gap: 30px;
}
.navbar {
  background-color: #fafafa;
}
.productDescription {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.Announcement {
  background-color: #db5c50;
  height: 27px;
  vertical-align: bottom;

  padding-bottom: 0px;
  padding-top: 5px;
}
.drop {
  font-size: 14px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.title {
  position: absolute;
}
@media (max-width: 1000 px) {
  .title {
    padding-left: 20vw;
  }
}
