.sec1 {
  background-image: url(../stickerimgs/glitchnoborder.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 20vw;
  height: 200px;
  padding-bottom: 100px;
  display: block;
}
.sec2 {
  background-image: url(../stickerimgs/secondfinalcamo.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 20vw;
  height: 200px;
  display: block;
}
.sec3 {
  background-image: url(../stickerimgs/maybefinalwater.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 20vw;
  height: 200px;
  display: block;
}
.sec4 {
  background-image: url(../stickerimgs/mtncenter.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 20vw;
  height: 200px;
  display: block;
}

.secgrid {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: space-around;
  align-items: center;
}
.sec1:hover {
  height: 300px;
  width: 25vw;
}
.sec2:hover {
  height: 300px;
  width: 25vw;
}
.sec3:hover {
  height: 300px;
  width: 25vw;
}
.sec4:hover {
  height: 300px;
  width: 25vw;
}
.bottombuf {
  padding-bottom: 100px;
}
