section {
  height: 100vh;
  width: 100%;
  padding: 6em;
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-wrap: wrap;

  justify-content: space-around;
  align-items: center;
  h1 {
    font-size: 5em;
  }
  p {
    width: 50%;
    opacity: 0.8;
    line-height: 1.5em;
    font-size: 1.5em;
  }
}

section:nth-of-type(1) {
  background-image: url(./gradpart1glitchs.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
section:nth-of-type(3) {
  background-image: url(./gradmtnp3.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  color: black;
}
section:nth-of-type(4) {
  background-image: url(./gradearthp4s.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  color: black;
}
section:nth-of-type(2) {
  background-image: url(./gradientpagepart2.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

[data-scroll] {
  transition: all 3s;
}
[data-scroll="in"] {
  opacity: 1;
  transform: translateX(0);
}
[data-scroll="out"] {
  opacity: 0;
  transform: translateX(-250px);
}
.glitchsticker {
  height: 300px;

  float: right;
}
